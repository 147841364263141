import { Employer } from "../models/employer.model";

export class LoadEmployers {
  public static readonly type = "[Employers] load employers";
  constructor() {}
}

export class AddEmployer {
  public static readonly type = "[Employers] add employer";
  constructor(
    public payload: Employer,
    public callbackSuccess: () => void,
    public callbackError: () => void,
  ) {}
}

export class UpdateEmployer {
  public static readonly type = "[Employers] update employer";
  constructor(
    public payload: Employer,
    public callbackSuccess: () => void,
    public callbackError: () => void,
  ) {}
}

export class RemoveEmployer {
  public static readonly type = "[Employers] remove employer";
  constructor(
    public employerId: number,
    public callbackSuccess: () => void,
    public callbackError: () => void,
  ) {}
}

export class LoadExportCompanyTypes {
  public static readonly type = "[Employers] load export company types"
}